.App {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.background-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
